import { getAssessment, getScores } from '@api/assessment';
import { getHistoricalData } from '@api/historicalArea';
import { getQuestionnairesNames } from '@api/questionnaires';
import Card from '@components/Atoms/Card';
import TabButton from '@components/Atoms/TabButton';
import Area from '@components/Charts/Area';
import ScoresPageContent from '@components/ScorePageContent';
import { WingDetails } from '@components/Star';
import { SET_GRAPH_DATA, useGlobalState } from '@context/GlobalStore';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { CreateStarCategoryInterface } from '@interfaces/index';
import MobileToggler from '@layouts/MainLayout/SecondaryNavigation/components/MobileToggler';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';

const ScoreGraphSection: React.FunctionComponent<SectionProps> = (props) => {
  const { data, participantId, loading } = props;
  const [state, dispatch] = useGlobalState();
  const [selectedTabDetails, setSelectedTabDetails] = useState<TabDetailsInterface>({
    id: 0,
    label: '',
    labelId: 1,
    actualItemId: 1,
  });
  const [questionnaireNames, setQuestionnaireNames] = useState<{ id: number; name: string }[] | []>(
    [],
  );
  const [isOpen, setIsOpen] = useState(false);
  const [starDataLoading, setStarDataLoading] = useState<boolean>(false);
  const { isDesktop, isMobile } = useWindowDimensions();

  const [areaData, setAreaData] = useState<number[]>([]);
  const [areaLabels, setAreaLabels] = useState<string[]>([]);
  const [areaStars, setAreaStars] = useState<WingDetails[][]>([]);
  const [historicalData, setHistoricalData] = useState<AssessmentDataInterface[]>([]);
  const [error, setError] = useState<string>('');
  const [recordsPerPage] = useState<number>(5);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [indexGraph, setIndexGraph] = useState<number>(9);
  const [selectedIndex, setSelectedIndex] = useState<number>(9);
  const historicalDataPerPage = isMobile ? 5 : 10;
  const [categoryQuestion, setCategoryQuestion] = useState<CreateStarCategoryInterface[]>([]);

  useEffect(() => {
    getQueNames();
  }, []);

  useEffect(() => {
    try {
      if (participantId && participantId !== 0) {
        getAssessment(1, isDesktop ? 10 : 5, selectedTabDetails.actualItemId, 0, participantId)
          .then((dt) => {
            const {
              result,
              areaData: areaDataResp,
              areaLabels: areaLabelsResp,
              areaStars: areaStarsResp,
              catScores,
              starDetails,
            } = dt;

            setAreaData(areaDataResp);
            setAreaLabels(areaLabelsResp);
            setAreaStars(areaStarsResp);
          })

          .catch((err: any) => {
            if (err) {
              setError('NO DATA, USER DID NOT COMPLETE ASSESSMENT');
            }
          });

        const getScoresData = async () => {
          setError('');

          try {
            const dt = await getScores(
              selectedTabDetails.actualItemId,
              state?.graphData?.assesmentId,
              participantId,
            );
            setCategoryQuestion(dt);
          } catch (err: any) {
            setCategoryQuestion([]);
          }
        };

        getScoresData();
      }
    } catch (err) {
      setAreaData([]);
      setAreaLabels([]);
      setAreaStars([]);
      setCategoryQuestion([]);
    }
  }, [
    dispatch,
    isDesktop,
    selectedTabDetails.actualItemId,
    state?.graphData?.assesmentId,
    participantId,
  ]);

  const getQueNames = async () => {
    const res = await getQuestionnairesNames();
    if (res === 401) {
      setQuestionnaireNames([]);
    } else {
      setQuestionnaireNames(res);
      setSelectedTabDetails({
        id: 0,
        label: res[0]?.name,
        labelId: 1,
        actualItemId: res[0]?.id || 0,
      });
    }
  };

  const tabButtons =
    questionnaireNames &&
    questionnaireNames.map((que: { id: number; name: string }, index: number) => ({
      id: index + 1,
      title: que?.name,
      actualItemId: que.id || 0,
    }));

  const getListItemClassName = (tag: string) =>
    tag ===
    (selectedTabDetails.label
      ? selectedTabDetails.label
      : questionnaireNames && questionnaireNames[0]?.name)
      ? styles.active
      : '';

  const handleTabChange = async (tab: {
    id: number;
    label: string;
    labelId: number;
    actualItemId: number;
  }) => {
    dispatch({ type: SET_GRAPH_DATA, payload: { assesmentId: 0, date: '', index: 0 } });
    await setSelectedTabDetails(tab);
  };

  const nextPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const prevPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleIndexGraph = (index: number) => {
    setIndexGraph(index);
  };

  useEffect(() => {
    const getHistorical = async () => {
      const dt = await getHistoricalData(
        selectedTabDetails.actualItemId,
        participantId,
        currentPage,
        historicalDataPerPage,
      );
      setHistoricalData(dt?.data?.reverse());
      setTotalPages(dt.pagination.TotalRecords);
    };

    if (selectedTabDetails.actualItemId && participantId && participantId !== 0) {
      getHistorical();
    }
  }, [currentPage, selectedTabDetails.actualItemId, historicalDataPerPage, participantId]);

  useEffect(() => {
    setIndexGraph(historicalData.length - 1);
    setSelectedIndex(historicalData.length - 1);
  }, [historicalData]);

  return (
    <Card className={styles.container}>
      {questionnaireNames && tabButtons && (
        <>
          <div className={styles.tabsContainer}>
            <TabButton
              selectedTab={selectedTabDetails}
              handleSelect={(tab: any) => {
                handleTabChange(tab);
              }}
              tabs={tabButtons}
              isMobileWrapEnabled
            />
          </div>

          <div className={styles.hsSubNavigation}>
            <MobileToggler
              openHandler={setIsOpen}
              isOpen={isOpen}
              name={selectedTabDetails.label}
            />
            {isOpen && (
              <ul>
                {questionnaireNames?.map((dt: { id: number; name: string }, index: number) => (
                  <li key={index} className={getListItemClassName(dt.name)}>
                    <a
                      onClick={() => {
                        setSelectedTabDetails({
                          id: index,
                          label: dt.name,
                          labelId: dt.id,
                          actualItemId: dt.id,
                        });
                        setIsOpen(false);
                      }}
                    >
                      {dt.name}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className={styles.tabContent}>
            <div className={styles.areaContainer}>
              <div className={styles.fullWidth}>
                {areaData.length > 0 && areaStars.length > 0 && areaLabels.length > 0 && (
                  <Area
                    values={historicalData}
                    stars={areaStars}
                    labels={areaLabels}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    nextPage={nextPage}
                    prevPage={prevPage}
                    setIndexGraph={(index: number) => handleIndexGraph(index)}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                  />
                )}
              </div>
            </div>

            <div className={styles.deepDiveRadarContainer}>
              {categoryQuestion?.length === 0 ? (
                <h5 className={styles.noAssessmentText}>
                  NO DATA, USER DID NOT COMPLETE ASSESSMENT
                </h5>
              ) : (
                <ScoresPageContent data={categoryQuestion} showButton />
              )}
            </div>
          </div>
        </>
      )}
    </Card>
  );
};

ScoreGraphSection.defaultProps = {
  data: undefined,
  loading: false,
};

interface SectionProps {
  data?: any;
  participantId: any;
  loading?: boolean;
}

interface TabDetailsInterface {
  id: number;
  label: string;
  labelId: number;
  actualItemId: number;
}

interface AssessmentDataInterface {
  assessment_id: number;
  user_id: number;
  questionnaire_id: number;
  created_at: string;
  score: number;
}

export default ScoreGraphSection;
