import React, { useContext, useEffect, useState } from 'react';
import MainLayout from '@layouts/MainLayout';
import ScorePageContent from '@components/ScorePageContent';
import { CreateStarCategoryInterface } from '@interfaces/index';
import { getScores } from '@api/assessment';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import LoadingPage from '@components/LoadingPage/LoadingPage';
import Button from '@components/Button';
import PageTitle from '@components/PageTitle';
import { SET_CURRENT_TAB, useGlobalState } from '@context/GlobalStore';
import { useHistory } from 'react-router';
import styles from './index.module.scss';

const ScoresPage: React.FunctionComponent = () => {
  const { activeSubNav } = useContext(SecondaryNavigationContext);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [state, dispatch] = useGlobalState();
  const history = useHistory();
  const user = localStorage.getItem('user');
  const parsedUser = JSON.parse(user || '{}');
  const [categoryQuestion, setCategoryQuestion] = useState<CreateStarCategoryInterface[]>([]);

  // Fetch the latest scores on tab change
  useEffect(() => {
    const getScoresData = async () => {
      setLoading(true);
      setError(false);

      try {
        const data = await getScores(activeSubNav.id, 0, null);
        setLoading(false);
        setCategoryQuestion(data);
      } catch (err: any) {
        if (JSON.parse(err.message).message) {
          history.push('/create-a-star');
        }
      }
    };

    if (activeSubNav.id === JSON.parse(sessionStorage.getItem('subNav') as any)?.id) {
      getScoresData();
    }
  }, [activeSubNav.id, history]);

  return (
    <MainLayout title="Scores">
      <div className={styles.hsTitleRoot}>
        <PageTitle title="Scores" />
        <div>
          <Button
            onClick={() => {
              dispatch({ type: SET_CURRENT_TAB, payload: { id: null, name: '' } });
              history.push('/create-a-star');
            }}
            type="primary"
            isButton
          >
            Set my star
          </Button>
        </div>
      </div>
      {error && !loading && <p>No scores available for the selected item.</p>}

      {loading && !error && <LoadingPage />}
      {!loading && !error && <ScorePageContent data={categoryQuestion} showButton />}
    </MainLayout>
  );
};

export default ScoresPage;
