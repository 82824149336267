import Card from '@components/Atoms/Card';
import { FocusAndProgressRootInterface } from '@interfaces/index';
import { Skeleton } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import React from 'react';
import Chart from 'react-apexcharts';
import styles from './index.module.scss';

const FocusAreasAndProgressSection: React.FunctionComponent<SectionProps> = (props) => {
  const { data, loading } = props;

  if (!data) return null;

  const categories = Object.keys(data);
  const oldestScores = categories.map((key) => data[key].oldest.score);
  const latestScores = categories.map((key) => data[key].latest.score);
  const oldestDates = categories.map(
    (key) => new Date(data[key].oldest.date).toISOString().split('T')[0],
  ); // Format date as YYYY-MM-DD
  const latestDates = categories.map(
    (key) => new Date(data[key].latest.date).toISOString().split('T')[0],
  ); // Format date as YYYY-MM-DD

  const chartOptions: ApexOptions = {
    chart: {
      type: 'bar',
      height: 450,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
        barHeight: '60%',
      },
    },
    colors: ['#9370DB', '#77DD77'], // Green for oldest, Purple for latest
    dataLabels: {
      enabled: false,
      style: {
        colors: ['#000'],
      },
    },
    xaxis: {
      categories,
      title: {
        // text: 'Score',
      },
    },
    legend: {
      position: 'right',
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
  };

  const series = [
    {
      name: 'Latest',
      data: latestScores,
    },
    {
      name: 'Oldest',
      data: oldestScores,
    },
  ];

  return (
    <Card className={styles.focusAreasAndProgressCard}>
      <h4 className={styles.cardTitle}>Focus Areas and Progress</h4>
      {loading ? (
        <Skeleton variant="rounded" width="100%" height={450} />
      ) : (
        <Chart options={chartOptions} series={series} type="bar" height={500} />
      )}
    </Card>
  );
};

FocusAreasAndProgressSection.defaultProps = {
  data: null,
  loading: false,
};

interface SectionProps {
  data?: FocusAndProgressRootInterface | null;
  loading?: boolean;
}

export default FocusAreasAndProgressSection;
