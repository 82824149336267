import { getUserByUniqueId } from '@api/users';
import DefaultButton from '@components/Atoms/DefaultButton';
import { UserDetailsByUniqueIdInterface } from '@interfaces/index';
import MainLayout from '@layouts/MainLayout';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, CircularProgress, TextField, Typography } from '@mui/material';
import { castResponse } from 'custom.d';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import styles from './index.module.scss';

const ParticipantReportHome: React.FunctionComponent = () => {
  const [participantSearchQuery, setParticipantSearchQuery] = useState('');
  const [participant, setParticipant] = useState<UserDetailsByUniqueIdInterface | null>(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const getParticipantDetails = async () => {
    setLoading(true);
    try {
      const response = await getUserByUniqueId(participantSearchQuery);
      if (response.success) {
        const data = castResponse<UserDetailsByUniqueIdInterface>(response);
        setParticipant(data);
        toast.success('Participant found successfully!');
        history.push(
          `/advisor/participant-report?participantId=${data.userId}&participantUniqueId=${participantSearchQuery}`,
        );
      } else {
        setParticipant(null);
        toast.error(response.message || 'No participant found');
      }
    } catch (error: any) {
      setParticipant(null);
      toast.error(error.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const onClickSearchParticipant = () => {
    if (!participantSearchQuery.trim()) {
      toast.error('Please enter an ID to search');

      return;
    }

    getParticipantDetails();
  };

  return (
    <MainLayout title="Participant Report" secondaryNavEnabled useAdvisorNav>
      <div className={styles.contentContainer}>
        <h5>Search Participant by ID</h5>
        <div className={styles.searchContainer}>
          <Autocomplete
            freeSolo
            options={[]}
            inputValue={participantSearchQuery}
            onInputChange={(event, newValue) => setParticipantSearchQuery(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Participant ID"
                placeholder="Enter participant's ID"
                fullWidth
              />
            )}
            className={styles.autoComplete}
          />
          <DefaultButton variant="primary" onClick={onClickSearchParticipant} disabled={loading}>
            {loading ? <CircularProgress size={24} color="inherit" /> : <SearchIcon />}
          </DefaultButton>
        </div>

        {!participant && !loading && (
          <Typography variant="body2" color="textSecondary" className={styles.noData}>
            Please enter participant ID to continue.
          </Typography>
        )}
      </div>
    </MainLayout>
  );
};

export default ParticipantReportHome;
