import { apiFetch } from '@helpers/fetch.helper';
import { parseAssessmentData, parseSaveAssessmentData, parseScores } from '@helpers/parser.helper';
import { CreateStarCategoryInterface, QuestionnaireInterface } from '@interfaces/index';
import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const getAssessment = async (pg = 1, lmit = 1, queId = 1, assessId = 0, uId = null) => {
  const formattedReq = {
    page: pg || 1,
    limit: lmit || 1,
    questionnaireId: queId || 1,
    assessmentId: assessId || 0,
    userId: uId,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}/questions-categories-and-scores`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  let result: QuestionnaireInterface[] = await response.json();
  result = result.slice((pg - 1) * lmit, pg * lmit);

  const assessmentData = result.map((item) => parseAssessmentData(item));
  const { catScores, avgScore, starDetails } = assessmentData[0];

  const areaData = assessmentData.map((item) => item.avgScore).reverse();
  const areaLabels = assessmentData.map((item) => item.creationDate.toString()).reverse();
  const areaStars = assessmentData.map((item) => item.starDetails).reverse();

  return { result, areaData, areaLabels, areaStars, catScores, avgScore, starDetails };
};

export const getScores = async (queId = 1, assessId = 0, uId = null) => {
  const formattedReq = {
    questionnaireId: queId || 1,
    assessmentId: assessId || 0,
    userId: uId,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}/questions-categories-and-scores`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  let data: CreateStarCategoryInterface[] = [];

  if (response.ok) {
    const results: QuestionnaireInterface[] = await response.json();

    const lastAssessment = results[results.length - 1];
    data = parseScores(lastAssessment.categories);
  }

  return data;
};

export const getQuestions = async (queId = 1, assessId = 0, uId = null) => {
  const formattedReq = {
    questionnaireId: queId || 1,
    assessmentId: assessId || 0,
    userId: uId,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}/questions-categories-and-scores`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  const result: any = await response.json();
  const data: any = parseScores(result[0].categories).flatMap((i) => i.questions);

  return data;
};

export const getCompareScoreWithPrevious = async (questionnaireId = 1) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/questionnaire-assessment-compare-with-previous/${questionnaireId}`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );
  const result: any = await response.json();

  return result;
};

export const getCompareScoreWithHighest = async (questionnaireId = 1) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/questionnaire-assessment-compare-with-highest/${questionnaireId}`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );
  const result: any = await response.json();

  return result;
};

export const getCompareScoreWithLowest = async (questionnaireId = 1) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/questionnaire-assessment-compare-with-lowest/${questionnaireId}`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );
  const result: any = await response.json();

  return result;
};

export const saveAssessment = async (data: CreateStarCategoryInterface[], questionnaireId = 1) => {
  const questions = parseSaveAssessmentData(data);
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}/save-questionnaire-responses/${questionnaireId}`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(questions),
    },
  );

  if (result.ok) {
    const responseData = await result.json();

    return { success: true, data: responseData };
  }

  const errorData = await result.json();

  return { success: false, error: errorData };
};
