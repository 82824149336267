import IndicatorCheck from '@assets/images/icons/indicator-check-red.svg';
import Card from '@components/Atoms/Card';
import Img from '@components/Atoms/Img';
import { Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';

const indicatorTexts = [
  'Below threshold score: Stress Levels, Sleep Quality',
  'Focus area selected: Stress Levels',
  'Wanted to talk to an expert: Stress Levels',
  'Used speak up',
  'Clicked an emergency help source: NHS Mental Health Helpline',
  'Low score vs organisation average: Stress Levels 1 vs 5.1',
  'Low score vs Hapstar average: Stress Levels 1 vs 6.9',
];

const InventionSection: React.FunctionComponent<SectionProps> = (props) => {
  const { data, loading } = props;
  const [isLoading, setIsLoading] = useState(loading);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <Card className={styles.inventoryCard}>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <div className={styles.top}>
            <Skeleton variant="rounded" height={30} width="40%" />
            <Skeleton variant="rounded" height={40} width="100%" />
          </div>

          <div className={styles.body}>
            <Skeleton variant="rounded" height={20} width="50%" />
            <Skeleton variant="rounded" height={20} width="50%" />
            <Skeleton variant="rounded" height={20} width="50%" />
            <Skeleton variant="rounded" height={20} width="50%" />
            <Skeleton variant="rounded" height={20} width="50%" />
            <Skeleton variant="rounded" height={20} width="50%" />
            <Skeleton variant="rounded" height={20} width="50%" />
            <Skeleton variant="rounded" height={20} width="50%" />
          </div>
        </div>
      ) : (
        <>
          <h4 className={styles.cardTitle}>Intervention Urgency Index: 7/7</h4>

          <div className={styles.barContainer}>
            <div className={styles.bar}>
              {[...Array(7)].map((_, idx) => (
                <div key={idx} className={`${styles.segment} ${styles[`segment${idx + 1}`]}`} />
              ))}
            </div>
            <div className={styles.indicator}>
              <div className={styles.arrow} />
              <span className={styles.value}>7</span>
            </div>
          </div>

          <div className={styles.indicatorContainer}>
            <h5>Indicators present:</h5>

            <div className={styles.indicators}>
              {indicatorTexts.map((text, idx) => (
                <div className={styles.indicator} key={idx}>
                  <Img
                    src={IndicatorCheck}
                    alt="indicator-check"
                    className={styles.indicatorIcon}
                  />
                  <p>{text}</p>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </Card>
  );
};

InventionSection.defaultProps = {
  data: undefined,
  loading: true,
};

interface SectionProps {
  data?: any;
  loading?: boolean;
}

export default InventionSection;
