import HapstarLogo from '@assets/images/logo.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './notFoundError.module.scss';

const NotFound: React.FunctionComponent = () => (
  <div className={styles.notFoundContainer}>
    <div className={styles.logoWrapper}>
      <img src={HapstarLogo} alt="Hapstar Logo" className={styles.logo} />
    </div>
    <div className={styles.content}>
      <h1 className={styles.errorCode}>404</h1>
      <h2 className={styles.title}>Oops! Page Not Found</h2>
      <p className={styles.message}>
        Sorry, the page you&apos;re looking for doesn&apos;t exist or might have been moved.
      </p>
      <Link to="/" className={styles.homeLink}>
        <DefaultButton>Back to Homepage</DefaultButton>
      </Link>
    </div>
  </div>
);

export default NotFound;
