import HelpIcon from '@assets/images/icons/help-circle.svg';
import HighTrendIcon from '@assets/images/icons/reporting/high-trend.svg';
import LowTrendIcon from '@assets/images/icons/reporting/low-trend-icon.svg';
import NeutralTrendIcon from '@assets/images/icons/reporting/neutral-trend-icon.svg';
import Card from '@components/Atoms/Card';
import Img from '@components/Atoms/Img';
import PopUpModal from '@components/PopUpModal';
import { Skeleton, Tooltip, Typography } from '@mui/material';
import { formatDateToDateMonthYear } from '@utils/date.util';
import { InitInfoDialog } from 'custom.d';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';

const ScoresSection: React.FunctionComponent<SectionProps> = (props) => {
  const { data, loading } = props;
  const [isLoading, setIsLoading] = useState(loading);
  const [infoDialog, setInfoDialog] = useState<{
    open: boolean;
    top: string;
    right: string;
    firstDescription: string;
    description: string;
    title: string;
  }>(InitInfoDialog);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const data1 = {
    lastUpdatedAt: '2024-09-10',
    scores: [
      {
        id: 1,
        category: 'Overall',
        score: 8,
        previousScore: 5,
        companyAvgScore: 6.5,
        overallHapstarScore: 7.5,
        date: '2024-09-10',
      },
      {
        id: 2,
        category: 'Mental',
        score: 5,
        previousScore: 5,
        companyAvgScore: 6.5,
        overallHapstarScore: 7.5,
        date: '2024-09-10',
      },
      {
        id: 3,
        category: 'Physical',
        score: 4,
        previousScore: 5,
        companyAvgScore: 6.5,
        overallHapstarScore: 7.5,
        date: '2024-09-10',
      },
      {
        id: 4,
        category: 'Financial',
        score: 9,
        previousScore: 5,
        companyAvgScore: 6.5,
        overallHapstarScore: 7.5,
        date: '2024-09-10',
      },
      {
        category: 'Social',
        score: 2,
        previousScore: 5,
        companyAvgScore: 6.5,
        overallHapstarScore: 7.5,
        date: '2024-10-12',
      },
      {
        id: 5,
        category: 'Career',
        score: 5,
        previousScore: 5,
        companyAvgScore: 6.5,
        overallHapstarScore: 7.5,
        date: '2024-09-10',
      },
    ],
  };

  const getScoreClass = (sc: number) => {
    if (sc >= 8) {
      return styles.highScore;
    }

    if (sc >= 5) {
      return styles.mediumScore;
    }

    return styles.lowScore;
  };

  const getDifferenceClass = (sc: number, averageScore: number) => {
    const difference = sc - averageScore;

    // greater than 1 green
    if (difference > 1) {
      return styles.highDifference;
    }

    // between 1 and -1 amber
    if (difference > -1 && difference < 1) {
      return styles.mediumDifference;
    }

    // lower than -1 red
    if (difference < -1) {
      return styles.lowDifference;
    }

    return styles.lowDifference;
  };

  const getTrendIcon = (sc: number, prevScore: number) => {
    // no need to equal only greater
    if (sc > prevScore) {
      return <Img src={HighTrendIcon} alt="high-trend" className={styles.trendIcon} />;
    }

    if (sc === prevScore) {
      return <Img src={NeutralTrendIcon} alt="neutral-trend" className={styles.trendIcon} />;
    }

    return <Img src={LowTrendIcon} alt="low-trend" className={styles.trendIcon} />;
  };

  return (
    <div className={styles.scoresContainer}>
      <Card className={styles.scoreCard}>
        <div className={styles.scoreCardHeader}>
          <h6>Check in Scores</h6>
          <Tooltip
            title="Check-in scores - Click for more details"
            followCursor
            onClick={() => {
              setInfoDialog({
                open: true,
                top: '650px',
                right: '43%',
                firstDescription:
                  'Your score is worked out using the Hapstar HPS (Happiness Promoter Score) methodology. This scoring system was worked out by experts, who have assessed hundreds of thousands of happiness data points and applied the correct weightings to accurately determine how happy you are in the form of a number.',
                description:
                  'The scores you inputted across all happiness drivers are translated to either a happiness promoter or detractor. These are then weighted and aggregated to determine your score. You will find that happiness swings dramatically from the mid-point (50) but becomes harder and harder to reach the end points (0 and 100). This is true in real life as well. It’s much easier to feel happier when you’re middling, than it is when you’re already extremely happy.',
                title: 'This is Check-in scores!',
              });
            }}
          >
            <img src={HelpIcon} alt="help" className={styles.helpIcon} />
          </Tooltip>
          {isLoading ? (
            <Skeleton variant="text" width={120} height={20} />
          ) : (
            <p>{`Last updated: ${formatDateToDateMonthYear(data1.lastUpdatedAt)}`}</p>
          )}
        </div>

        {isLoading ? (
          <div className={styles.loadingContainer}>
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
          </div>
        ) : (
          <>
            <table className={styles.scoresTable}>
              <thead>
                <tr>
                  <th />
                  <th align="right">Trend</th>
                  <th>vs Programme</th>
                  <th>vs Hapstar</th>
                </tr>
              </thead>
              <tbody>
                {data1.scores.map((score) => {
                  const scoreValue = Number(score.score).toFixed(1);
                  const trendCircleScoreClass = getScoreClass(Number(score.score));
                  const vsProgrammeValue = Number(score.companyAvgScore).toFixed(1);
                  const differenceProgrammeClass = getDifferenceClass(
                    Number(score.score),
                    Number(score.companyAvgScore),
                  );

                  const vsHapstarValue = Number(score.overallHapstarScore).toFixed(1);
                  const differenceHapstarClass = getDifferenceClass(
                    Number(score.score),
                    Number(score.overallHapstarScore),
                  );

                  return (
                    <tr key={score.category}>
                      <td align="left">{score.category}</td>
                      <td className={styles.trend} align="left">
                        <div className={`${styles.circle} ${trendCircleScoreClass}`}>
                          {score.score}
                        </div>

                        <Tooltip
                          title={
                            <>
                              <Typography sx={{ color: 'white' }}>
                                Latest Score: {Number(score.score).toFixed(1) || 0.0}
                              </Typography>
                              <Typography sx={{ color: 'white' }}>
                                Previous Score: {Number(score.previousScore).toFixed(1) || 0.0}
                              </Typography>
                              <Typography sx={{ color: 'white' }}>
                                Score difference:{' '}
                                {(Number(score.score) - Number(score.previousScore)).toFixed(1) ||
                                  0.0}
                              </Typography>
                            </>
                          }
                          followCursor
                        >
                          <div>
                            {getTrendIcon(Number(score.score), Number(score.previousScore))}
                          </div>
                        </Tooltip>
                      </td>
                      <td className={styles.roundedRectangle} align="center">
                        <div className={`${differenceProgrammeClass}`}>
                          <p>{`${scoreValue} vs ${vsProgrammeValue}`}</p>
                        </div>
                      </td>

                      <td className={styles.roundedRectangle} align="center">
                        <div className={`${differenceHapstarClass}`}>
                          <p>{`${scoreValue} vs ${vsHapstarValue}`}</p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </Card>

      <Card className={styles.scoreCard}>
        <div className={styles.scoreCardHeader}>
          <h6>Deep Dive Scores</h6>
          <Tooltip
            title="Deep dive scores - Click for more details"
            followCursor
            onClick={() => {
              setInfoDialog({
                open: true,
                top: '650px',
                right: '8%',
                firstDescription:
                  'Your score is worked out using the Hapstar HPS (Happiness Promoter Score) methodology. This scoring system was worked out by experts, who have assessed hundreds of thousands of happiness data points and applied the correct weightings to accurately determine how happy you are in the form of a number.',
                description:
                  'The scores you inputted across all happiness drivers are translated to either a happiness promoter or detractor. These are then weighted and aggregated to determine your score. You will find that happiness swings dramatically from the mid-point (50) but becomes harder and harder to reach the end points (0 and 100). This is true in real life as well. It’s much easier to feel happier when you’re middling, than it is when you’re already extremely happy.',
                title: 'This is Deep dive scores!',
              });
            }}
          >
            <img src={HelpIcon} alt="help" className={styles.helpIcon2} />
          </Tooltip>
        </div>

        {isLoading ? (
          <div className={styles.loadingContainer}>
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
          </div>
        ) : (
          <>
            <table className={styles.scoresTable}>
              <thead>
                <tr>
                  <th />
                  <th align="right">Trend</th>
                  <th>vs Programme</th>
                  <th>vs Hapstar</th>
                </tr>
              </thead>
              <tbody>
                {data1.scores.map((score) => {
                  const scoreValue = Number(score.score).toFixed(1);
                  const trendCircleScoreClass = getScoreClass(Number(score.score));
                  const vsProgrammeValue = Number(score.companyAvgScore).toFixed(1);
                  const differenceProgrammeClass = getDifferenceClass(
                    Number(score.score),
                    Number(score.companyAvgScore),
                  );

                  const vsHapstarValue = Number(score.overallHapstarScore).toFixed(1);
                  const differenceHapstarClass = getDifferenceClass(
                    Number(score.score),
                    Number(score.overallHapstarScore),
                  );

                  return (
                    <tr key={score.category}>
                      <td align="left">
                        {score.category}
                        <br />
                        <span className={styles.catDate}>
                          {formatDateToDateMonthYear(score.date)}
                        </span>
                      </td>
                      <td className={styles.trend} align="left">
                        <div className={`${styles.circle} ${trendCircleScoreClass}`}>
                          {score.score}
                        </div>

                        <Tooltip
                          title={
                            <>
                              <Typography sx={{ color: 'white' }}>
                                Latest Score: {Number(score.score).toFixed(1) || 0.0}
                              </Typography>
                              <Typography sx={{ color: 'white' }}>
                                Previous Score: {Number(score.previousScore).toFixed(1) || 0.0}
                              </Typography>
                              <Typography sx={{ color: 'white' }}>
                                Score difference:{' '}
                                {(Number(score.score) - Number(score.previousScore)).toFixed(1) ||
                                  0.0}
                              </Typography>
                            </>
                          }
                          followCursor
                        >
                          <div>
                            {getTrendIcon(Number(score.score), Number(score.previousScore))}
                          </div>
                        </Tooltip>
                      </td>
                      <td className={styles.roundedRectangle} align="center">
                        <div className={`${differenceProgrammeClass}`}>
                          <p>{`${scoreValue} vs ${vsProgrammeValue}`}</p>
                        </div>
                      </td>

                      <td className={styles.roundedRectangle} align="center">
                        <div className={`${differenceHapstarClass}`}>
                          <p>{`${scoreValue} vs ${vsHapstarValue}`}</p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </Card>

      <PopUpModal
        open={infoDialog.open}
        setOpen={() => setInfoDialog(InitInfoDialog)}
        top={infoDialog.top}
        right={infoDialog.right}
        firstDescription={infoDialog.firstDescription}
        description={infoDialog.description}
        title={infoDialog.title}
        overlay
      />
    </div>
  );
};

ScoresSection.defaultProps = {
  data: undefined,
  loading: true,
};

interface SectionProps {
  data?: any;
  loading?: boolean;
}

export default ScoresSection;
