import homeActive from '../assets/images/icons/home-active.svg';
import homeInactive from '../assets/images/icons/home-inactive.svg';

export default [
  {
    name: 'Participant Report',
    path: '/advisor/participant-report-home',
    exact: true,
    icon: homeInactive,
    activeIcon: homeActive,
  },
];
