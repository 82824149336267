import Card from '@components/Atoms/Card';
import { BoostersAndDrainersInterfaceData } from '@interfaces/index';
import { Grid, Skeleton, Stack } from '@mui/material';
import React from 'react';
import styles from './index.module.scss';

const BoostersAndDrainersSection: React.FunctionComponent<SectionProps> = (props) => {
  const { data, loading } = props;

  return (
    <div className={styles.mainContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={styles.cardContainer}>
            <h6 className={styles.subTitle}>Top boosters</h6>
            <p className={styles.subDescription}>Your people feel good about</p>
            {loading ? (
              <Stack direction="column" alignItems="center" gap={3} sx={{ marginTop: 5 }}>
                {[0, 1, 2, 3, 4].map((item) => (
                  <Skeleton
                    variant="rounded"
                    className={styles.loadingItem}
                    key={item}
                    width="100%"
                    height={44}
                  />
                ))}
              </Stack>
            ) : data && data.topBoosters.length > 0 ? (
              <div className={styles.boostersList}>
                {data.topBoosters.map((item, index) => (
                  <div key={index} className={styles.itemContent}>
                    <p>{item.question}</p>
                    <div className={`${styles.circle} ${styles.liliac}`}>
                      <p>{item.score}</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.emptyState}>No boosters</div>
            )}
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card className={styles.cardContainer}>
            <h6 className={styles.subTitle}>Top drainers</h6>
            <p className={styles.subDescription}>Your people don’t feel good about</p>

            {loading ? (
              <Stack direction="column" alignItems="center" gap={3} sx={{ marginTop: 5 }}>
                {[0, 1, 2, 3, 4].map((item) => (
                  <Skeleton
                    variant="rounded"
                    className={styles.loadingItem}
                    key={item}
                    width="100%"
                    height={44}
                  />
                ))}
              </Stack>
            ) : data && data.topDrainers.length > 0 ? (
              <div className={styles.drainersList}>
                {data.topDrainers.map((item, index) => (
                  <div key={index} className={styles.itemContent}>
                    <p>{item.question}</p>
                    <div className={`${styles.circle} ${styles.yellow}`}>
                      <p>{item.score}</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.emptyState}>No drainers</div>
            )}
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card className={styles.cardContainer}>
            <h6 className={styles.subTitle}>Top work/career boosters</h6>
            <p className={styles.subDescription}>Your people feel good about their career</p>

            {loading ? (
              <Stack direction="column" alignItems="center" gap={3} sx={{ marginTop: 5 }}>
                {[0, 1, 2, 3, 4].map((item) => (
                  <Skeleton
                    variant="rounded"
                    className={styles.loadingItem}
                    key={item}
                    width="100%"
                    height={44}
                  />
                ))}
              </Stack>
            ) : data && data.topCareerBoosters.length > 0 ? (
              <div className={styles.boostersList}>
                {data.topCareerBoosters.map((item, index) => (
                  <div key={index} className={`${styles.itemContent} ${styles.orange}`}>
                    <p>{item.question}</p>
                    <div className={`${styles.circle} ${styles.orange}`}>
                      <p>{item.score}</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.emptyState}>No boosters</div>
            )}
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card className={styles.cardContainer}>
            <h6 className={styles.subTitle}>Top work/career drainers</h6>
            <p className={styles.subDescription}>Your people don’t feel good about their career</p>

            {loading ? (
              <Stack direction="column" alignItems="center" gap={3} sx={{ marginTop: 5 }}>
                {[0, 1, 2, 3, 4].map((item) => (
                  <Skeleton
                    variant="rounded"
                    className={styles.loadingItem}
                    key={item}
                    width="100%"
                    height={44}
                  />
                ))}
              </Stack>
            ) : data && data.topCareerDrainers.length > 0 ? (
              <div className={styles.careerDrainersList}>
                {data.topCareerDrainers.map((item, index) => (
                  <div key={index} className={styles.itemContent}>
                    <p>{item.question}</p>
                    <div className={`${styles.circle} ${styles.yellow}`}>
                      <p>{item.score}</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.emptyState}>No drainers</div>
            )}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

BoostersAndDrainersSection.defaultProps = {
  data: {
    topBoosters: [],
    topDrainers: [],
    topCareerBoosters: [],
    topCareerDrainers: [],
  },
  loading: false,
};

interface SectionProps {
  data?: BoostersAndDrainersInterfaceData;
  loading?: boolean;
}

export default BoostersAndDrainersSection;
