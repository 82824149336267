import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const getHistoricalData = async (
  queId: number,
  uId: number | null,
  pg: number,
  prPage: number,
) => {
  const formattedReq = {
    questionnaireId: queId || 1,
    userId: uId,
    page: pg || 0,
    perPage: prPage || 10,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}/historical-data`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });
  const result: any = await response.json();

  return result;
};
