import {
  ApiResponse,
  BoostersAndDrainersInterfaceData,
  ErrorDetail,
  FocusAndProgressRootInterface,
  UserActionData,
  UserAssessmentCountInterface,
  UserCreatedPlansCountInterface,
  UserLearningResourcesCountInterface,
} from '@interfaces/index';
import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const getFocusAndProgressData = async (
  userId: number,
  startDate: string | Date | null,
  endDate: string | Date | null,
) => {
  const formattedReq = {
    userId,
    startDate,
    endDate,
  };

  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-user-focus-areas-progress`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  const responseData: ApiResponse<FocusAndProgressRootInterface> | ApiResponse<ErrorDetail> =
    await response.json();

  return responseData;
};

export const getUserAssessmentCount = async (
  userId: number,
  startDate: string | Date | null,
  endDate: string | Date | null,
) => {
  const formattedReq = {
    userId,
    startDate,
    endDate,
  };

  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-user-assessments-counts`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  const responseData: ApiResponse<UserAssessmentCountInterface> | ApiResponse<ErrorDetail> =
    await response.json();

  return responseData;
};

export const getUserEarlyWarningCount = async (
  userId: number,
  startDate: string | Date | null,
  endDate: string | Date | null,
) => {
  const formattedReq = {
    userId,
    startDate,
    endDate,
  };

  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-user-early-warning-counts`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  const responseData: ApiResponse<{ earlyWarningDisplayed: number }> | ApiResponse<ErrorDetail> =
    await response.json();

  return responseData;
};

export const getUserImprovePlanCount = async (
  userId: number,
  startDate: string | Date | null,
  endDate: string | Date | null,
) => {
  const formattedReq = {
    userId,
    startDate,
    endDate,
  };

  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-user-improve-plan-actions-counts`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  const responseData: ApiResponse<UserCreatedPlansCountInterface> | ApiResponse<ErrorDetail> =
    await response.json();

  return responseData;
};

export const getUserReflectionsCount = async (
  userId: number,
  startDate: string | Date | null,
  endDate: string | Date | null,
) => {
  const formattedReq = {
    userId,
    startDate,
    endDate,
  };

  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-user-reflections-counts`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  const responseData: ApiResponse<{ userReflectionsCount: number }> | ApiResponse<ErrorDetail> =
    await response.json();

  return responseData;
};

export const getUserBookingsCount = async (
  userId: number,
  startDate: string | Date | null,
  endDate: string | Date | null,
) => {
  const formattedReq = {
    userId,
    startDate,
    endDate,
  };

  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-user-success-booking-counts`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  const responseData: ApiResponse<{ userBookingCount: number }> | ApiResponse<ErrorDetail> =
    await response.json();

  return responseData;
};

export const getUserLearningResourcesCount = async (
  userId: number,
  startDate: string | Date | null,
  endDate: string | Date | null,
) => {
  const formattedReq = {
    userId,
    startDate,
    endDate,
  };

  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-user-learning-resources-access-counts`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  const responseData: ApiResponse<UserLearningResourcesCountInterface> | ApiResponse<ErrorDetail> =
    await response.json();

  return responseData;
};

export const getBoostersAndDrainers = async (userId: number) => {
  const formattedReq = {
    userId,
  };

  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-user-top-boosters-and-drainers`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  const responseData: ApiResponse<BoostersAndDrainersInterfaceData> | ApiResponse<ErrorDetail> =
    await response.json();

  return responseData;
};

export const getUserActions = async (userId: number) => {
  const formattedReq = {
    userId,
  };

  const response = await fetch(`${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-user-actions`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  const responseData: ApiResponse<UserActionData> | ApiResponse<ErrorDetail> =
    await response.json();

  return responseData;
};
