import ActionsCompletedIcon from '@assets/images/icons/reporting/actions-completed-icon.svg';
import ActionsCreatedIcon from '@assets/images/icons/reporting/actions-created-icon.svg';
import ArticleIcon from '@assets/images/icons/reporting/articles-read-icon.svg';
import ExpertSessionsIcon from '@assets/images/icons/reporting/bookings-icon.svg';
import CheckInIcon from '@assets/images/icons/reporting/checkins-icon.svg';
import DeepDiveIcon from '@assets/images/icons/reporting/deepdives-icon.svg';
import EarlyWarningIcon from '@assets/images/icons/reporting/early-warning-icon.svg';
import PlanProgressIcon from '@assets/images/icons/reporting/plan-progress-icon.svg';
import ReflectionsIcon from '@assets/images/icons/reporting/reflections-written-icon.svg';
import VideoIcon from '@assets/images/icons/reporting/videos-watched-icon.svg';
import Card from '@components/Atoms/Card';
import { OverviewCountInterface } from '@interfaces/index';
import { Grid, Skeleton } from '@mui/material';
import React from 'react';
import styles from './index.module.scss';

const INITIAL_OVERVIEW_DATA = {
  checkInCount: 0,
  deepDiveCount: 0,
  earlyWarningCount: 0,
  actionsCreatedCount: 0,
  actionsCompletedCount: 0,
  planProgressCount: 0,
  articlesReadCount: 0,
  videosWatchedCount: 0,
  reflectionsCount: 0,
  expertSessionsCount: 0,
};

const ActivityOverviewSection: React.FunctionComponent<SectionProps> = (props) => {
  const { data, loading } = props;

  const items = [
    { icon: CheckInIcon, label: 'Check-ins', value: data?.checkInCount || 0 },
    { icon: DeepDiveIcon, label: 'Deep Dives', value: data?.deepDiveCount || 0 },
    { icon: EarlyWarningIcon, label: 'Early Warning System', value: data?.earlyWarningCount || 0 },
    { icon: ActionsCreatedIcon, label: 'Actions Created', value: data?.actionsCreatedCount || 0 },
    {
      icon: ActionsCompletedIcon,
      label: 'Actions Completed',
      value: data?.actionsCompletedCount || 0,
    },
    {
      icon: PlanProgressIcon,
      label: 'Plan Progress',
      value: `${data?.planProgressCount}%` || '0%',
    },
    { icon: ArticleIcon, label: 'Articles Read', value: data?.articlesReadCount || 0 },
    { icon: VideoIcon, label: 'Videos Watched', value: data?.videosWatchedCount || 0 },
    { icon: ReflectionsIcon, label: 'Reflections Written', value: data?.reflectionsCount || 0 },
    { icon: ExpertSessionsIcon, label: 'Expert Sessions', value: data?.expertSessionsCount || 0 },
  ];

  return (
    <Card className={styles.recommendationsCard}>
      <h4 className={styles.cardTitle}>Activity Overview (Last 30 days)</h4>

      {loading ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rounded" width="100%" height={150} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rounded" width="100%" height={150} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rounded" width="100%" height={150} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rounded" width="100%" height={150} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rounded" width="100%" height={150} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rounded" width="100%" height={150} />
          </Grid>
        </Grid>
      ) : (
        <div className={styles.gridContainer}>
          {items.map((item, index) => (
            <div className={styles.gridItem} key={index}>
              <img src={item.icon} alt={item.label} className={styles.icon} />
              <span className={styles.value}>{item.value}</span>
              <span className={styles.label}>{item.label}</span>
            </div>
          ))}
        </div>
      )}
    </Card>
  );
};

ActivityOverviewSection.defaultProps = {
  data: INITIAL_OVERVIEW_DATA,
  loading: false,
};

interface SectionProps {
  data?: OverviewCountInterface;
  loading?: boolean;
}

export default ActivityOverviewSection;
