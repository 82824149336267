import { getImproveActionsForDashboard } from '@api/actionPlan';
import {
  getBoostersAndDrainers,
  getFocusAndProgressData,
  getUserActions,
  getUserAssessmentCount,
  getUserBookingsCount,
  getUserEarlyWarningCount,
  getUserImprovePlanCount,
  getUserLearningResourcesCount,
  getUserReflectionsCount,
} from '@api/participantReporting';
import SkyBluePrevious from '@assets/images/icons/sky-blue-previous.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import ActionsSection from '@components/Molecules/PariticipantReport/ActionsSection';
import ActivityOverviewSection from '@components/Molecules/PariticipantReport/ActivityOverviewSection';
import BoostersAndDrainersSection from '@components/Molecules/PariticipantReport/BoostersAndDrainersSection';
import FocusAreasAndProgressSection from '@components/Molecules/PariticipantReport/FocusAreasAndProgressSection';
import InventionSection from '@components/Molecules/PariticipantReport/InventionSection';
import ScoreGraphSection from '@components/Molecules/PariticipantReport/ScoreGraphSection';
import ScoresSection from '@components/Molecules/PariticipantReport/ScoresSection';
import {
  BoostersAndDrainersInterfaceData,
  FocusAndProgressRootInterface,
  OverviewCountInterface,
  UserActionData,
  UserAssessmentCountInterface,
  UserCreatedPlansCountInterface,
  UserLearningResourcesCountInterface,
} from '@interfaces/index';
import MainLayout from '@layouts/MainLayout';
import { castResponse } from 'custom.d';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styles from './index.module.scss';

const INITIAL_OVERVIEW_DATA = {
  checkInCount: 0,
  deepDiveCount: 0,
  earlyWarningCount: 0,
  actionsCreatedCount: 0,
  actionsCompletedCount: 0,
  planProgressCount: 0,
  articlesReadCount: 0,
  videosWatchedCount: 0,
  reflectionsCount: 0,
  expertSessionsCount: 0,
};

const INITIAL_ACTIONS = {
  pendingUserActions: [],
  pendingHapstarActions: [],
  completedUserActions: [],
  completedHapstarActions: [],
};

const AdvisorReporting: React.FunctionComponent = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const participantIdQuery = urlParams.get('participantId');
  const participantId = participantIdQuery ? Number(participantIdQuery) : 0;
  const startDate = moment().subtract(30, 'days').format('YYYY-MM-DD'); // 30 days before today
  const endDate = moment().format('YYYY-MM-DD'); // Today's date
  const [focusAreaData, setFocusAreaData] = useState<FocusAndProgressRootInterface | null>(null);
  const [focusAreaDataLoading, setFocusAreaDataLoading] = useState<boolean>(true);
  const [overviewDataCount, setOverviewDataCount] =
    useState<OverviewCountInterface>(INITIAL_OVERVIEW_DATA);
  const [overviewDataLoading, setOverviewDataLoading] = useState<boolean>(true);

  const [boostersAndDrainers, setBoostersAndDrainers] = useState<BoostersAndDrainersInterfaceData>({
    topBoosters: [],
    topDrainers: [],
    topCareerBoosters: [],
    topCareerDrainers: [],
  });
  const [boostersAndDrainersLoading, setBoostersAndDrainersLoading] = useState<boolean>(true);
  const [userActions, setUserActions] = useState<UserActionData>(INITIAL_ACTIONS);
  const [actionsLoading, setActionsLoading] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    if (participantId && participantId !== 0) {
      getFocusAreaAndProgress();
      getCountsForActivityOverview();
      getBoosterAndDrainerData();
      getAllPlanActions();
    }
  }, [participantId]);

  const getFocusAreaAndProgress = async () => {
    try {
      const response = await getFocusAndProgressData(participantId, null, null);

      if (response?.success) {
        const data = castResponse<FocusAndProgressRootInterface>(response);
        setFocusAreaData(data);
      } else {
        setFocusAreaData(null);
      }
    } catch (error) {
      setFocusAreaDataLoading(false);
      setFocusAreaData(null);
    } finally {
      setFocusAreaDataLoading(false);
    }
  };

  const getCountsForActivityOverview = async () => {
    try {
      setOverviewDataLoading(true);

      const [response1, response2, response3, response4, response5, response6, response7] =
        await Promise.all([
          getUserAssessmentCount(participantId, startDate, endDate),
          getUserEarlyWarningCount(participantId, startDate, endDate),
          getUserImprovePlanCount(participantId, startDate, endDate),
          getUserReflectionsCount(participantId, startDate, endDate),
          getUserBookingsCount(participantId, startDate, endDate),
          getUserLearningResourcesCount(participantId, startDate, endDate),
          getImproveActionsForDashboard(participantId),
        ]);

      if (response1.success) {
        const data1 = castResponse<UserAssessmentCountInterface>(response1);
        setOverviewDataCount((prevState) => ({
          ...prevState,
          checkInCount: data1.checkIns ? Number(data1.checkIns) : 0,
          deepDiveCount: data1.deepDives ? Number(data1.deepDives) : 0,
        }));
      }

      if (response2.success) {
        const data2 = castResponse<{ earlyWarningDisplayed: number }>(response2);
        setOverviewDataCount((prevState) => ({
          ...prevState,
          earlyWarningCount: data2.earlyWarningDisplayed ? Number(data2.earlyWarningDisplayed) : 0,
        }));
      }

      if (response3.success) {
        const data3 = castResponse<UserCreatedPlansCountInterface>(response3);
        setOverviewDataCount((prevState) => ({
          ...prevState,
          actionsCreatedCount: data3.totalActionsCount ? Number(data3.totalActionsCount) : 0,
          actionsCompletedCount: data3.totalActionsCompletedCount
            ? Number(data3.totalActionsCompletedCount)
            : 0,
        }));
      }

      if (response4.success) {
        const data4 = castResponse<{ userReflectionsCount: number }>(response4);
        setOverviewDataCount((prevState) => ({
          ...prevState,
          reflectionsCount: data4.userReflectionsCount ? Number(data4.userReflectionsCount) : 0,
        }));
      }

      if (response5.success) {
        const data5 = castResponse<{ userBookingCount: number }>(response5);
        setOverviewDataCount((prevState) => ({
          ...prevState,
          expertSessionsCount: data5.userBookingCount ? Number(data5.userBookingCount) : 0,
        }));
      }

      if (response6.success) {
        const data6 = castResponse<UserLearningResourcesCountInterface>(response6);
        setOverviewDataCount((prevState) => ({
          ...prevState,
          articlesReadCount: data6.articleContent ? Number(data6.articleContent) : 0,
          videosWatchedCount: data6.videoContent ? Number(data6.videoContent) : 0,
        }));
      }

      if (response7) {
        setOverviewDataCount((prevState) => ({
          ...prevState,
          planProgressCount: response7.totalProgress ? Number(response7.totalProgress) : 0,
        }));
      }
    } catch (error) {
      setOverviewDataCount(INITIAL_OVERVIEW_DATA);
    } finally {
      setOverviewDataLoading(false);
    }
  };

  const getBoosterAndDrainerData = async () => {
    try {
      setBoostersAndDrainersLoading(true);
      const response = await getBoostersAndDrainers(participantId);
      if (response.success) {
        const data = castResponse<BoostersAndDrainersInterfaceData>(response);
        setBoostersAndDrainers(data);
      } else {
        setBoostersAndDrainers({
          topBoosters: [],
          topDrainers: [],
          topCareerBoosters: [],
          topCareerDrainers: [],
        });
      }
    } catch {
      setBoostersAndDrainers({
        topBoosters: [],
        topDrainers: [],
        topCareerBoosters: [],
        topCareerDrainers: [],
      });
    } finally {
      setBoostersAndDrainersLoading(false);
    }
  };

  const getAllPlanActions = async () => {
    try {
      setActionsLoading(true);
      const response = await getUserActions(participantId);
      if (response.success) {
        const data = castResponse<UserActionData>(response);
        setUserActions(data);
      } else {
        setUserActions(INITIAL_ACTIONS);
      }
    } catch {
      setUserActions(INITIAL_ACTIONS);
    } finally {
      setActionsLoading(false);
    }
  };

  if (participantIdQuery === null || participantIdQuery === undefined || participantId === 0) {
    return (
      <MainLayout title="Advisor Reporting" secondaryNavEnabled useAdvisorNav isAdvisorPage>
        <div className={styles.errorContainer}>
          <h4>Participant Id is missing. Please enter it correctly and try again.</h4>
          <DefaultButton onClick={() => history.push('/advisor/participant-report-home')}>
            Back to home page
          </DefaultButton>
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout title="Advisor Reporting" secondaryNavEnabled useAdvisorNav isAdvisorPage>
      <div className={styles.contentContainer}>
        <div className={styles.topSection}>
          <div>
            <h2 className={styles.mainTitle}>Participant Snapshot</h2>
            <h5 className={styles.participantUniqueValue}>Participant Id: {participantIdQuery}</h5>
          </div>

          <div
            className={styles.navigateBack}
            onClick={() => history.push('/advisor/participant-report-home')}
          >
            <Img src={SkyBluePrevious} alt="HS Previous" className={styles.previous} />
            <h5>Pariticipant Report</h5>
          </div>
        </div>

        <InventionSection />
        {/* <AdvisorRecommendationsSection /> */}
        <ScoresSection />

        <FocusAreasAndProgressSection data={focusAreaData} loading={focusAreaDataLoading} />
        <ActivityOverviewSection data={overviewDataCount} loading={overviewDataLoading} />

        <BoostersAndDrainersSection
          data={boostersAndDrainers}
          loading={boostersAndDrainersLoading}
        />

        <ActionsSection
          data={userActions}
          refetchActions={getAllPlanActions}
          loading={actionsLoading}
        />

        <ScoreGraphSection participantId={participantId} />
      </div>
    </MainLayout>
  );
};

export default AdvisorReporting;
