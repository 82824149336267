import { getActions, saveAction } from '@api/actions';
import { getQuestions } from '@api/assessment';
import { getFocusAreaData } from '@api/focusArea';
import blueTray from '@assets/images/icons/blueTray.svg';
import PageTitle from '@components//PageTitle';
import Card from '@components/Atoms/Card';
import Button from '@components/Button';
import CategoryScoreCard from '@components/CategoryScoreCard';
import ColoredBox from '@components/ColoredBox';
import ModalContent from '@components/ModalContent';
import AddPlanFocusArea from '@components/ModalContent/AddFocusArea';
import CreateEditAction from '@components/ModalContent/CreateEditAction';
import CreateImprovePlan from '@components/ModalContent/CreateImprovePlan';
import { CreateStarContext } from '@context/CreateStarContext';
import { ADD_ACTIONS, SET_ACTIONS, SET_FOCUS_AREAS, useGlobalState } from '@context/GlobalStore';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import { smoothScrollTo } from '@helpers/general.helpers';
import { useAppSelector } from '@hooks/useAppSelector';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { CreateStarCategoryInterface } from '@interfaces/index';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { RootState } from 'store';
import styles from './index.module.scss';

const NewScorePlanSection: React.FunctionComponent<ActionsPageContentProps> = (props) => {
  const { data, isActionPage, isWizard } = props;
  const [takeActionModal, setTakeActionModal] = useState(false);
  const [focusAreas, setFocusAreas] = useState<any>([]);
  const [state, dispatch] = useGlobalState();
  const { categoryQuestion } = useContext(CreateStarContext);
  const { isMobile } = useWindowDimensions();
  const [editFocusArea, setEditFocusArea] = useState(false);
  const [isAddingAction, setIsAddingAction] = useState(false);
  const [actionData, setActionData] = useState<any>([]);
  const [questions, setQuestions] = useState<any>([]);
  const [setActionErrorMessage] = useState<any>(null);
  const [isEditing, setIsEditing] = useState<any>(false);
  const { activeSubNav } = useContext(SecondaryNavigationContext);
  const user = localStorage.getItem('user');
  const parsedUser = JSON.parse(user || '{}');

  const [createActionModal, setCreateActionModal] = useState<{ open: boolean; data: any }>({
    open: false,
    data: null,
  });

  const reduxStarScores = useAppSelector((st: RootState) => st.starScores);

  useEffect(() => {
    const getFocusArea = async () => {
      const focusData = await getFocusAreaData(activeSubNav.id, state?.graphData?.assesmentId);
      const newFocusData = focusData.map((i: any) => ({
        ...i,
        name: i.focus_area,
        color: i.color,
      }));

      dispatch({ type: SET_FOCUS_AREAS, payload: newFocusData });
    };

    getFocusArea();
  }, [activeSubNav.id, state?.graphData?.assesmentId, isEditing, categoryQuestion]);

  useEffect(() => {
    const getActions1 = async () => {
      const focusData = await getActions(activeSubNav.id, state?.graphData?.assesmentId);

      if (!focusData.message) {
        dispatch({ type: SET_ACTIONS, payload: focusData });
      }
    };

    getActions1();
  }, [activeSubNav.id, state?.graphData?.assesmentId, isEditing, isAddingAction]);

  const handleEditFocusArea = () => {
    setEditFocusArea(!editFocusArea);
  };

  const editFocusAreaData = async (data2: any, questionnaireIds: any) => {
    dispatch({ type: SET_FOCUS_AREAS, payload: data2 });
    setIsEditing((prev: any) => !prev);
  };

  const sendActionData = async (item: any) => {
    const focusAreasData1 = data.flatMap((k) => k.questions).find((l) => l.name === item.name);

    const response = await saveAction(
      {
        focusAreaId: item.id,
        actionText: item.action_text,
      },
      activeSubNav.id,
      state?.graphData?.assesmentId,
    );

    if (response === 400) {
      setActionErrorMessage('You cannot add more than 10 actions');
    }

    if (response !== 400) {
      dispatch({
        type: ADD_ACTIONS,
        payload: {
          title: focusAreasData1?.name,
          score: focusAreasData1?.score,
          id: item?.id,
          action_text: item.action_text,
        },
      });
    }

    setIsAddingAction(!isAddingAction);
  };

  useEffect(() => {
    const getAllQuestions = async () => {
      const questionsData = await getQuestions(
        activeSubNav.id,
        state?.graphData?.assesmentId,
        null,
      );

      setQuestions(questionsData);
      setFocusAreas(questionsData);
    };

    getAllQuestions();
  }, [activeSubNav.id, state?.graphData?.assesmentId]);

  useEffect(() => {
    if (state.focusAreas.length > 0) {
      questions.flatMap((i: any) =>
        state.focusAreas.flatMap((k: any) => {
          if (i.name === k.name) {
            setFocusAreas((prev: any) =>
              prev.map((l: any) => ({
                ...l,
                isSelected: l.name === i.name ? !l.isSelected : l.isSelected,
                questionId: i.id,
              })),
            );
          }

          return [];
        }),
      );
    }
  }, [state.focusAreas, questions]);

  return (
    <>
      {(() => {
        switch (reduxStarScores.subNavigationStep) {
          case 'noPlanAndFocusAreas':
            smoothScrollTo(0, 150);

            return (
              <div className="container-fluid">
                <div className={styles.hsRoot}>
                  <PageTitle isWizzard={isWizard} title="Act" subtitle=" " />
                </div>

                <div className={styles.actionContainer}>
                  <div className={styles.cardContainer}>
                    {reduxStarScores.selectedFocusAreas.length === 0 ? (
                      <Card>
                        <h4>Deep dive completed</h4>
                        <h5>
                          You haven&apos;t selected any focus areas at this time - and that&apos;s
                          perfectly fine.
                        </h5>
                        <h5>
                          Want a personalised action plan? Simply choose your focus areas below and
                          we&apos;ll create one for you.
                        </h5>
                        <h5>
                          Otherwise, you can return to this deep dive another time when you feel
                          ready.
                        </h5>
                      </Card>
                    ) : (
                      <Card>
                        <h4>It’s time to act!</h4>
                        <h5>
                          You&apos;ve selected your focus areas—now it&apos;s time to enhance how
                          you feel about them.
                        </h5>
                        <h5>
                          We&apos;ve tailored a plan just for you, but it&apos;s not set in stone.
                        </h5>
                        <h5>
                          Dive into your{' '}
                          {reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.questionText ||
                            ' '}{' '}
                          plan, you&apos;ll have the flexibility to incorporate your own actions
                          into your plan as you progress.
                        </h5>
                      </Card>
                    )}
                  </div>
                  <div className={styles.coloredBoxContainer}>
                    <div className={styles.coloredBox}>
                      <ColoredBox
                        title="My Focus Areas"
                        color="blue"
                        isEditable
                        setEditFocusArea={setEditFocusArea}
                      >
                        <div className={styles.focusContainer}>
                          {reduxStarScores?.selectedFocusAreas?.length > 0 &&
                            reduxStarScores?.selectedFocusAreas?.map((focusArea: any) => (
                              <div key={focusArea.id} className={styles.hsCategoryContainer}>
                                <CategoryScoreCard
                                  category={focusArea.name}
                                  score={focusArea.score}
                                  color={focusArea.color}
                                />
                              </div>
                            ))}
                          {reduxStarScores?.selectedFocusAreas?.length < 1 && (
                            <div className={styles.noFocusContainer}>
                              <img src={blueTray} className={styles.blueTray} alt="blue tray" />
                              <p className={styles.noChosenAreaText}>
                                You haven’t chosen any focus areas
                              </p>
                              <Button type="more" isButton onClick={() => setEditFocusArea(true)}>
                                Add a focus area
                                <i className="icon icon-plus-circle" />
                              </Button>
                            </div>
                          )}
                        </div>
                        <div className={`${styles.footerImage} ${styles.footerContainer}`} />
                      </ColoredBox>
                    </div>
                  </div>
                </div>
              </div>
            );

          case 'planCreatedWithFocusAreas':
          case 'hasPlanAndUpdatedFocusAreas':
            smoothScrollTo(0, 150);

            return (
              <div className="container-fluid">
                <div className={styles.hsRoot}>
                  <PageTitle isWizzard={isWizard} title="Act" subtitle=" " />
                </div>

                <div className={styles.actionContainer}>
                  <div className={styles.cardContainer}>
                    <Card>
                      <h4>It’s time to act!</h4>
                      <h5>
                        You&apos;ve selected your focus areas—now it&apos;s time to enhance how you
                        feel about them.
                      </h5>
                      <h5>
                        We&apos;ve tailored a plan just for you, but it&apos;s not set in stone.
                      </h5>
                      <h5>
                        Dive into your{' '}
                        {reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.questionText ||
                          ' '}{' '}
                        plan, you&apos;ll have the flexibility to incorporate your own actions into
                        your plan as you progress.
                      </h5>
                    </Card>
                  </div>
                  <div className={styles.coloredBoxContainer}>
                    <div className={styles.coloredBox}>
                      <ColoredBox
                        title="My Focus Areas"
                        color="blue"
                        isEditable
                        setEditFocusArea={setEditFocusArea}
                      >
                        <div className={styles.focusContainer}>
                          {reduxStarScores?.selectedFocusAreas?.length > 0 &&
                            reduxStarScores?.selectedFocusAreas?.map((focusArea: any) => (
                              <div key={focusArea.id} className={styles.hsCategoryContainer}>
                                <CategoryScoreCard
                                  category={focusArea.name}
                                  score={focusArea.score}
                                  color={focusArea.color}
                                />
                              </div>
                            ))}
                          {reduxStarScores?.selectedFocusAreas?.length < 1 && (
                            <div className={styles.noFocusContainer}>
                              <img src={blueTray} className={styles.blueTray} alt="blue tray" />
                              <p className={styles.noChosenAreaText}>
                                You haven’t chosen any focus areas
                              </p>
                              <Button type="more" isButton onClick={() => setEditFocusArea(true)}>
                                Add a focus area
                                <i className="icon icon-plus-circle" />
                              </Button>
                            </div>
                          )}
                        </div>
                        <div className={`${styles.footerImage} ${styles.footerContainer}`} />
                      </ColoredBox>
                    </div>
                  </div>
                </div>
              </div>
            );

          case 'hasPlanNoFocusAreas':
          case 'hasPlanAndRemovedFocusAreas':
            smoothScrollTo(0, 150);

            return (
              <div className="container-fluid">
                <div className={styles.hsRoot}>
                  <PageTitle isWizzard={isWizard} title="Act" subtitle=" " />
                </div>

                <div className={styles.actionContainer}>
                  <div className={styles.cardContainer}>
                    <Card>
                      <h4>It’s time to act!</h4>
                      <h5>You have decided not to choose focus areas for now.</h5>
                      <h5>
                        You can still choose focus areas below before going to your plan, or you can
                        always re-take this deep dive later and choose your focus areas then.
                      </h5>
                      <h5>
                        We&apos;ve created a plan just for you, but it&apos;s not set in stone.
                      </h5>
                      <h5>
                        Dive into your{' '}
                        {reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.questionText ||
                          ' '}{' '}
                        plan, you&apos;ll have the flexibility to incorporate your own actions into
                        your plan as you progress.
                      </h5>
                    </Card>
                  </div>
                  <div className={styles.coloredBoxContainer}>
                    <div className={styles.coloredBox}>
                      <ColoredBox
                        title="My Focus Areas"
                        color="blue"
                        isEditable
                        setEditFocusArea={setEditFocusArea}
                      >
                        <div className={styles.focusContainer}>
                          {reduxStarScores?.selectedFocusAreas?.length > 0 &&
                            reduxStarScores?.selectedFocusAreas?.map((focusArea: any) => (
                              <div key={focusArea.id} className={styles.hsCategoryContainer}>
                                <CategoryScoreCard
                                  category={focusArea.name}
                                  score={focusArea.score}
                                  color={focusArea.color}
                                />
                              </div>
                            ))}
                          {reduxStarScores?.selectedFocusAreas?.length < 1 && (
                            <div className={styles.noFocusContainer}>
                              <img src={blueTray} className={styles.blueTray} alt="blue tray" />
                              <p className={styles.noChosenAreaText}>
                                You haven’t chosen any focus areas
                              </p>
                              <Button type="more" isButton onClick={() => setEditFocusArea(true)}>
                                Add a focus area
                                <i className="icon icon-plus-circle" />
                              </Button>
                            </div>
                          )}
                        </div>
                        <div className={`${styles.footerImage} ${styles.footerContainer}`} />
                      </ColoredBox>
                    </div>
                  </div>
                </div>
              </div>
            );

          default:
            return null;
        }
      })()}

      <Modal
        isOpen={createActionModal.open}
        onRequestClose={() => setCreateActionModal({ open: false, data: null })}
        contentLabel="Improve Action Modal"
        ariaHideApp={false}
        style={{
          content: {
            width: isMobile ? '95%' : undefined,
          },
        }}
      >
        <ModalContent
          onClose={() => setCreateActionModal({ open: false, data: null })}
          hasBackground
          title="Create your action"
        >
          <CreateImprovePlan
            closeModal={() => setCreateActionModal({ open: false, data: null })}
            improvePlan={reduxStarScores?.deepDiveQuestionWithPlans?.[0]?.question?.questionText}
            id={
              reduxStarScores.subNavigationStep === 'planCreatedWithFocusAreas' &&
              reduxStarScores?.createdImprovementPlan
                ? reduxStarScores?.createdImprovementPlan?.id
                : reduxStarScores?.deepDiveQuestionWithPlans?.[0]?.question?.improvePlans?.[0]
                    ?.id || 0
            }
            handleRefetch={() => null}
            actionData={createActionModal.data}
            focusAreaSuggestionsEnabled
          />
        </ModalContent>
      </Modal>

      <Modal
        isOpen={takeActionModal}
        onRequestClose={() => setTakeActionModal(false)}
        contentLabel="Example Modal"
        ariaHideApp={false}
        style={{
          content: {
            width: isMobile ? '90%' : undefined,
          },
        }}
      >
        <ModalContent
          onClose={() => setTakeActionModal(false)}
          hasBackground
          title="Create your action"
        >
          <CreateEditAction
            sendActionData={(item: any) => sendActionData(item)}
            closeModal={() => setTakeActionModal(false)}
            focusArea={actionData?.name}
            score={actionData?.score}
            id={actionData?.id}
            color={actionData?.color}
            suggestions={[]}
          />
        </ModalContent>
      </Modal>

      <Modal
        isOpen={editFocusArea}
        onRequestClose={handleEditFocusArea}
        contentLabel="Example Modal"
        ariaHideApp={false}
        style={{
          content: {
            width: isMobile ? '90%' : undefined,
          },
        }}
      >
        <ModalContent
          onClose={() => setEditFocusArea(false)}
          hasBackground
          title="Edit a focus area"
        >
          <AddPlanFocusArea
            focusAreas={focusAreas && focusAreas}
            onClose={() => {
              setEditFocusArea(false);
            }}
            setFocusAreas={setFocusAreas}
            sendFocusData={(data5, questionnaireIds) => editFocusAreaData(data5, questionnaireIds)}
            isActionPage={isActionPage}
            maxSelectCount={3}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

NewScorePlanSection.defaultProps = {
  isActionPage: false,
  isWizard: false,
};

interface ActionsPageContentProps {
  data: CreateStarCategoryInterface[];
  isActionPage?: boolean;
  isWizard?: boolean;
}

export default NewScorePlanSection;
